import * as React from 'react'
import { AppBar, Container, Toolbar, Typography } from '@mui/material'

import './Header.css'

function Header() {
  return (
    <>
      <AppBar position="static" className="header">
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            className="cure-main-container"
            sx={{
              display: { xs: 'grid', md: 'flex' },
              gridGap: { xs: 16, md: 'flex' },
              paddingTop: { xs: '16px', md: '0' },
              justifyContent: { xs: 'center', md: 'left' }
            }}
          >
            <a href="/">
              <img alt="menu-nav-logo" src="https://assets.cure.com/img/main-site/cure-logo.png" />
            </a>
            <Typography
              variant="h5"
              noWrap
              sx={{
                mr: 2,
                fontSize: { xs: 22, md: '2.0rem' },
                fontFamily: 'Roboto',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              Claims Manual Referral
            </Typography>
            <div></div>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}

export default Header
