import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import './global.css'

import HomePage from './pages/Home/Home'

const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route element={<HomePage />} exact path="/" />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
