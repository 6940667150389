import * as React from 'react'
import { Box, Button, CircularProgress, Container, FormControl, InputAdornment, InputLabel, MenuItem, TextField, Typography } from '@mui/material'
import Select from 'react-select'
import { submitClaimsReferral } from '../../services/Api'
import Header from '../../components/Header/Header'
import './Home.css'

function HomePage() {
  const [selectedOption, setSelectedOption] = React.useState()
  const [claimNumber, setClaimNumber] = React.useState('00000000')
  const [showSpinner, setShowSpinner] = React.useState(false)
  const [disableButton, setDisableButton] = React.useState(false)
  const [message, setMessage] = React.useState()

  React.useEffect(() => {
    if (claimNumber && claimNumber !== '00000000' && claimNumber.length === 8 && selectedOption) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }, [claimNumber, selectedOption])
  const handleSubmit = async () => {
    setShowSpinner(true)
    setDisableButton(true)
    const apiResponse = await submitClaimsReferral({
      field1: `CLM-${claimNumber}`,
      field2: selectedOption.value
    })
    const { data } = apiResponse
    setMessage(data)
    setTimeout(() => {
      window.location.reload()
    }, 5000)
    setShowSpinner(false)
    setDisableButton(true)
    setSelectedOption('1')
    setClaimNumber('00000000')
  }

  // const options = [
  //   { label: 'Undisclosed household members', value: 'Undisclosed household members' },
  //   { label: 'Undisclosed operator', value: 'Undisclosed operator' },
  //   { label: 'Vehicle ownership', value: 'Vehicle ownership' },
  //   { label: 'Residency', value: 'Residency' },
  //   { label: 'Garaging address', value: 'Garaging address' },
  //   { label: 'Livery/commercial usage', value: 'Livery/commercial usage' },
  //   {
  //     label: 'Undisclosed medical condition', value: 'Undisclosed medical condition'
  //   },
  //   {
  //     label: 'Driving record', value: 'Driving record'
  //   },
  //   { label: 'Claims Fraud- Individual level', value: 'Claims Fraud- Individual level' },
  //   { label: 'Claims Fraud- Entity level (Medical provider)', value: 'Claims Fraud- Entity level (Medical provider)' },
  //   { label: 'Claims Fraud- Entity level (Other than medical provider)', value: 'Claims Fraud- Entity level (Other than medical provider)' }
  // ]

  const options = [
    { label: 'Claims Fraud- Entity level (Medical provider)', value: 'Claims Fraud- Entity level (Medical provider)' },
    { label: 'Claims Fraud- Individual level (Experian)', value: 'Claims Fraud- Individual level (Experian)' },
    { label: 'Claims Fraud- Individual level (EDR)', value: 'Claims Fraud- Individual level (EDR)' },
    { label: 'Claims Fraud- Individual level (Other)', value: 'Claims Fraud- Individual level (Other)' },
    { label: 'Claims Fraud- Entity level (Other than medical provider)', value: 'Claims Fraud- Entity level (Other than medical provider)' },
    { label: 'Undisclosed household members', value: 'Undisclosed household members' },
    { label: 'Undisclosed operator', value: 'Undisclosed operator' },
    { label: 'Vehicle ownership', value: 'Vehicle ownership' },
    { label: 'Residency', value: 'Residency' },
    { label: 'Garaging address', value: 'Garaging address' },
    { label: 'Livery/commercial usage', value: 'Livery/commercial usage' },
    { label: 'Undisclosed medical condition', value: 'Undisclosed medical condition' },
    { label: 'Driving record', value: 'Driving record' }
  ]

  return (
    <>
      <Header />
      <Container maxWidth="md" className="home">
        <Box sx={{ my: 4 }}>
          <FormControl fullWidth>
            <TextField
              label="Claim Number"
              sx={{ m: 1, width: '25ch' }}
              value={claimNumber}
              onChange={event => setClaimNumber(event.target.value.replace(/\D/g, ''))}
              InputProps={{
                startAdornment: <InputAdornment position="start">CLM-</InputAdornment>
              }}
              inputProps={{ maxLength: 8 }}
            />
          </FormControl>
          <br />
          <br />
          <Select
            value={selectedOption}
            onChange={selectedOption => setSelectedOption(selectedOption)}
            options={options}
            classNamePrefix="filter"
            placeholder="Referral reason"
          />
          <br />
          <br />
          {message && (
            <Typography variant="overline" display="block" gutterBottom>
              {message}
            </Typography>
          )}
          <br />
          <br />
          <Button
            disabled={disableButton}
            variant="contained"
            onClick={() => handleSubmit()}
            {...(showSpinner && { endIcon: <CircularProgress size={16} style={{ color: 'white' }} /> })}
          >
            Submit
          </Button>
        </Box>
      </Container>
    </>
  )
}
export default HomePage
